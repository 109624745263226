<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body p-md-5 text-center">
        <h2 class="mb-4 fw-bold text-success">AMCHARA RETREAT INFO</h2>

        <div class="row my-5">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <img
              src="@/assets/images/retreats/uk.jpg"
              height="300px"
              class="w-100 mb-4"
              alt="Amchara UK"
            />
            <a
              href="https://www.amchara.com/retreat-information"
              target="_blank"
              to="/retreat-info/uk"
              class="btn btn-outline-success btn-lg"
              >View UK Info</a
            >
          </div>
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <img
              src="@/assets/images/retreats/malta.jpg"
              height="300px"
              class="w-100 mb-4"
              alt="Amchara UK"
            />
            <a
              href="https://www.amchara.com/retreat-information-malta"
              target="_blank"
              to="/retreat-info/malta"
              class="btn btn-outline-success btn-lg"
              >View Malta Info</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
